import { Stack } from '@jarvis/web-stratus-client'
import { ACCOUNT_TYPE_COMPANY, ContentStackStacks } from './Constants'
import sha256 from 'crypto-js/sha256'

/**
 * Get the content stack's stack for the given stack
 * @param stack
 * @returns {{env_name: string, api_key: string, delivery_token: string}}
 */
export const getContentStackCredentials = (stack) => {
  switch (stack) {
    case Stack.pie:
      return ContentStackStacks.pie
    case Stack.stage:
      return ContentStackStacks.stage
    case Stack.prod:
      return ContentStackStacks.prod
    default:
      return ContentStackStacks.pie
  }
}
export const getPortalOobeStack = (stack) => {
  let url = ''
  switch (stack) {
    case Stack.stage:
      url = 'https://smb.stage.portalshell.int.hp.com'
      break
    case Stack.prod:
      url = 'https://admin.hpsmart.com'
      break
    default:
      url = 'https://smb.pie.portalshell.int.hp.com'
  }
  return url
}

export const get123Stack = (stack) => {
  let url = ''
  switch (stack) {
    case Stack.stage:
      url = 'https://123-stage.hpoobe.com'
      break
    case Stack.prod:
      url = 'https://123.hp.com'
      break
    default:
      url = 'https://123-test.hpoobe.com'
  }
  return url
}

export const getWebBasedSetupPath = (baseURL) =>
  `${baseURL}/onboard?tenant.type=${ACCOUNT_TYPE_COMPANY}`

export const formatNumber = (number, maxLength, fillString) => {
  return number.toString().padStart(maxLength, fillString)
}

export const compareVersions = (versionA, versionB) => {
  if (versionA === versionB) {
    return 0
  }

  const splitA = versionA.split('.')
  const splitB = versionB.split('.')

  const maxLength = Math.max(splitA.length, splitB.length)

  for (let i = 0; i < maxLength; i++) {
    const valueA = splitA[i] || '0'
    const valueB = splitB[i] || '0'
    if (parseInt(valueA) > parseInt(valueB)) {
      return 1
    }

    if (parseInt(valueA) < parseInt(valueB)) {
      return -1
    }
  }

  return 0
}

export const hashString = async (input, salt) => {
  return sha256(input + sha256(salt).toString()).toString()
}
